export enum Contestant {
  BRESEIS = 'BRESEIS',
  IVAR = 'IVAR',
  ORAN = 'ORAN',
  AISSATA = 'AISSATA',
}

export const CONTESTANT_IDS = Object.values(
  Contestant,
) as readonly Contestant[];

export const CONTESTANTS = [
  { contestantId: Contestant.BRESEIS, name: 'Bréseïs' },
  { contestantId: Contestant.IVAR, name: 'Ivar' },
  { contestantId: Contestant.ORAN, name: 'Oran' },
  { contestantId: Contestant.AISSATA, name: 'Aissata' },
];
