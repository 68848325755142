import { Box, Button, Typography } from '@mui/material';
import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CONTESTANTS } from 'constants/contestant.constants';
import { VoteSteps } from 'constants/vote.constants';
import { useMedia } from 'hooks';
import { ContestantId } from 'types/contestant.types';

import { ContestantCard } from 'components/@cards';

type Props = {
  selectedContestantIds: ContestantId[];
  handleContestantSelect: (
    contestantId: ContestantId,
    isSelected: boolean,
  ) => void;
  handleStepChange: (step: VoteSteps) => void;
};

const SelectContestantsView = ({
  handleStepChange,
  selectedContestantIds,
  handleContestantSelect,
}: Props) => {
  // shuffle contestants to randomize who is on the top of the page on mobile
  const contestants = useMemo(() => shuffle(CONTESTANTS), []);
  const { isMobile, isTablet } = useMedia();

  return (
    <>
      <Box textAlign="center">
        <Typography
          variant="h3"
          color="#000"
          fontSize={{ xs: 24, lg: 64 }}
          fontWeight={800}
        >
          <FormattedMessage id="vote.contestants.title" />
        </Typography>
      </Box>
      {isMobile && <Box height={520} />}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position={{ xs: 'absolute', sm: 'relative', lg: 'relative' }}
        left={{ lg: 56 }}
        top={{ xs: 24, sm: 0 }}
        gap={{ sm: 2, lg: 0 }}
        alignSelf={{ sm: 'center' }}
        width={{ xs: '100%', sm: 500, lg: 1184 }}
        flexDirection={{ xs: 'column', lg: 'row' }}
        sx={{
          '.MuiBox-root:nth-child(even) .contestant-img': {
            top: { lg: -8 },
            right: { lg: 0 },
          },
        }}
      >
        {contestants.map((contestant, index) => (
          <ContestantCard
            key={contestant.contestantId}
            index={index + 1}
            contestant={contestant}
            selectedContestantIds={selectedContestantIds}
            handleContestantSelect={handleContestantSelect}
          />
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        {!isMobile && !isTablet && (
          <Box>
            <Typography variant="subtitle1" color="neutral01.800">
              <FormattedMessage
                id="vote.select_count.label"
                values={{ count: selectedContestantIds.length }}
              />
            </Typography>
          </Box>
        )}

        <Button
          disabled={selectedContestantIds.length !== 3}
          variant="contained"
          color="secondary"
          sx={{ py: 2, px: 3, width: { xs: '100%', sm: '50%', lg: 'auto' } }}
          onClick={() => handleStepChange(VoteSteps.submitVote)}
        >
          <FormattedMessage id="vote.btn.next.label" />
        </Button>
      </Box>
    </>
  );
};

export default SelectContestantsView;
