import { AxiosResponse } from 'axios';

import type {
  CreateUserParams,
  UpdateUserParams,
  User,
} from 'types/user.types';

import RestApiService from './';

export default {
  getUser(this: RestApiService, userId: User['id']) {
    return this.api.get<User>(`/api/users/${userId}`);
  },
  createUser(this: RestApiService, params: CreateUserParams) {
    return this.api.post<CreateUserParams, AxiosResponse<User>>(
      `/api/users`,
      params,
    );
  },
  updateUser(this: RestApiService, params: UpdateUserParams) {
    return this.api.post<UpdateUserParams, AxiosResponse<User>>(
      `/api/users`,
      params,
    );
  },
};
