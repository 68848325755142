import { AxiosResponse } from 'axios';

import { SubmitVoteParams } from 'types/vote.types';

import RestApiService from '..';

export default {
  submitVote(this: RestApiService, params: SubmitVoteParams) {
    return this.api.post<SubmitVoteParams, AxiosResponse<void>>(
      `/api/votes/submit`,
      params,
    );
  },
};
