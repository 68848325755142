import {
  BrowserTracing,
  init,
  reactRouterV6Instrumentation,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import config from 'config';

export const initSentry = () => {
  init({
    ...config.SENTRY,
    sampleRate: 0.1,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
  });
};

export const sentryCreateBrowserRouter =
  wrapCreateBrowserRouter(createBrowserRouter);
