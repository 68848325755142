import type { SVGProps } from 'react';

const SvgCheckbox = (props: SVGProps<SVGSVGElement>, stroke?: string) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <rect
      x={3}
      y={3}
      width={34}
      height={34}
      stroke={stroke ? stroke : 'black'}
      strokeWidth={6}
    />
  </svg>
);
export default SvgCheckbox;
