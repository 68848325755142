import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { MainLayout } from 'components/@layout';

const Confirm = () => {
  return (
    <MainLayout>
      <Stack gap={3}>
        <Box width={{ xs: '246px', sm: 'auto' }}>
          <Typography variant="h2">
            <FormattedMessage id="confirm.title" />
          </Typography>
        </Box>
        <Box width={{ xs: '246px', sm: '484px' }}>
          <Typography variant="h5" whiteSpace="pre-line">
            <FormattedMessage id="confirm.subtitle" />
          </Typography>
        </Box>
      </Stack>
    </MainLayout>
  );
};

export default Confirm;
