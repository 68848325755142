import { Box, Container, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { VoteSteps } from 'constants/vote.constants';
import { useMedia } from 'hooks';

import { BackButton, Footer } from 'components/@common';
import { LogoBntm } from 'components/@icons';
import StickyFooter from 'components/@vote/StickyFooter';

interface Props {
  children: ReactNode;
  handleStepChange: (step: VoteSteps) => void;
  currentStep: VoteSteps;
  numberOfSelectedContestants: number;
}

const VoteLayout = ({
  children,
  handleStepChange,
  currentStep,
  numberOfSelectedContestants,
}: Props) => {
  const { isMobile } = useMedia();
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      minWidth="100vw"
      color="#000"
    >
      <Box flexGrow={1} display="flex" overflow="hidden">
        <Container
          sx={{
            mt: { xs: 1, lg: 5 },
            mb: { xs: 3, lg: 8 },
          }}
        >
          <Stack
            spacing={{ xs: 2, lg: 5 }}
            width={{ lg: 1184 }}
            height={{ xs: '100%', lg: 'fit-content' }}
          >
            <Box textAlign={'center'} display="flex" alignItems="center">
              <Box
                flexGrow={1}
                flexBasis={0}
                display="flex"
                alignItems="center"
                justifyContent="start"
              >
                <BackButton
                  currentStep={currentStep}
                  handleStepChange={handleStepChange}
                />
              </Box>
              <Box
                flexGrow={1}
                flexBasis={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  svg: {
                    width: { xs: 86, lg: 177 },
                    height: { xs: 32, lg: 64 },
                  },
                }}
              >
                <LogoBntm />
              </Box>

              <Box flexBasis={0} flexGrow={1}></Box>
            </Box>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              gap={{ xs: 3, lg: 5 }}
              position="relative"
            >
              {children}
            </Box>
          </Stack>
        </Container>
      </Box>
      {isMobile && currentStep === VoteSteps.selectContestants && (
        <StickyFooter selectedContestantsAmount={numberOfSelectedContestants} />
      )}
      <Footer />
    </Box>
  );
};

export default VoteLayout;
