import { Box, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { VoteSteps } from 'constants/vote.constants';

import { IcoChevronLeft } from 'components/@icons';

type Props = {
  handleStepChange: (step: VoteSteps) => void;
  currentStep: VoteSteps;
};
const BackButton = ({ handleStepChange, currentStep }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (currentStep === VoteSteps.selectContestants) return navigate('/');
    handleStepChange(VoteSteps.selectContestants);
  };

  return (
    <Button
      sx={{
        minWidth: 0,
        border: 0,
        padding: 0,
      }}
      variant="text"
      onClick={handleClick}
      startIcon={
        <Box className="button-icon" display="flex" alignItems="center">
          <IcoChevronLeft height={32} width={32} />
        </Box>
      }
    >
      <FormattedMessage id="vote.btn.back.label" />
    </Button>
  );
};

export default BackButton;
