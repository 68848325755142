import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Home from './Home';

export default createRoute({
  element: <Home />,
  path: Routes.Root,
});
