import { Box, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { VoteSteps } from 'constants/vote.constants';
import { useSubmitVote } from 'queries';
import { ContestantId } from 'types/contestant.types';

import { ErrorCard } from 'components/@common';
import { Form } from 'components/@forms';

import FormPage from '../FormPage';

interface FormFields {
  email: string;
  privacyPolicy: boolean;
  promotionalConsent: boolean;
}
type Props = {
  selectedContestantIds: ContestantId[];
  handleStepChange: (step: VoteSteps) => void;
};

const SubmitVoteView = ({ selectedContestantIds }: Props) => {
  const navigate = useNavigate();
  const { isPending, submitVote } = useSubmitVote();
  const [error, setError] = useState({ error: false, message: '' });
  const DEFAULT_VALUES: FormFields = {
    email: '',
    privacyPolicy: false,
    promotionalConsent: false,
  };

  const handleSubmit = (values: FormFields) => {
    submitVote(
      { contestantIds: selectedContestantIds, ...values },
      {
        onSuccess: () => {
          navigate(`${Routes.Confirm}`);
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            if (error.response?.status === 409) {
              setError({ error: true, message: 'form.validation.voted' });
            } else {
              setError({ error: true, message: 'submit.error.invalid.vote' });
            }
          }
        },
      },
    );
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography variant="h3" color="neutral01.800" textAlign="center">
        <FormattedMessage id="vote.submit.title" />
      </Typography>
      <Typography
        variant="body1"
        color="neutral01.800"
        mt={1}
        mb={3}
        textAlign="center"
      >
        <FormattedMessage id="vote.submit.subtitle" />
      </Typography>
      {error.error && (
        <ErrorCard label={<FormattedMessage id={error.message} />} />
      )}
      <Form<FormFields> onSubmit={handleSubmit} defaultValues={DEFAULT_VALUES}>
        <FormPage isPending={isPending} />
      </Form>
    </Box>
  );
};

export default SubmitVoteView;
