import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Confirm from './Confirm';

export default createRoute({
  element: <Confirm />,
  path: Routes.Confirm,
});
