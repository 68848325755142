import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Vote from './Vote';

export default createRoute({
  element: <Vote />,
  path: Routes.Vote,
});
