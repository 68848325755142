import { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral01[800],
      '&.Mui-focused': {
        color: theme.palette.neutral01[800],
      },
    }),
  },
};
