import { useMutation } from '@tanstack/react-query';

import { apiService } from 'services';
import { SubmitVoteParams } from 'types/vote.types';

const submitVote = async (params: SubmitVoteParams) => {
  const { data } = await apiService.submitVote(params);
  return data;
};

export const useSubmitVote = () => {
  const { mutate, isPending, isError, isSuccess } = useMutation({
    mutationFn: submitVote,
  });

  return { submitVote: mutate, isPending, isError, isSuccess };
};
