import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { RouterProvider } from 'react-router-dom';

import { isProd } from 'utils/env.utils';

import { sentryCreateBrowserRouter } from 'services/sentry';

import { RouteBoundary } from 'components/@boundaries';

import { routes } from './routes';

const router = sentryCreateBrowserRouter(routes);

const Pages = () => {
  const intl = useIntl();

  return (
    <Suspense>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'seo.title',
          })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({
            id: 'seo.description',
          })}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Streamz Voting" />
        {!isProd() && <meta name="robots" content="noindex,nofollow" />}

        <meta
          property="og:description"
          content={intl.formatMessage({
            id: 'seo.description',
          })}
        />
        <meta property="og:image" content={'/android-chrome-144x144.png'} />
      </Helmet>
      <RouteBoundary>
        <RouterProvider router={router} />
      </RouteBoundary>
    </Suspense>
  );
};

export default Pages;
