import { Box, Container, Grid, Link, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import imgLogoTransparent from 'assets/logo/logo-transparent.png';

import { LogoBntm } from 'components/@icons';

const Footer = () => {
  return (
    <Box
      bgcolor="#000"
      color="#fff"
      component="footer"
      sx={() => ({
        position: 'relative',
        bottom: 0,
        left: 0,
        right: 0,
      })}
    >
      <Container sx={{ my: 5 }}>
        <Stack gap={{ xs: 3, sm: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={4} pb={{ xs: 3, sm: 0, md: 0 }} zIndex={15}>
              <img
                src={imgLogoTransparent}
                width={139}
                height={24}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://www.streamz.be/')}
                alt="Streamz logo"
              />
            </Grid>
            <Grid item xs={12} sm={4} textAlign={{ sm: 'center' }} zIndex={15}>
              <LogoBntm width={'66px'} height={'24px'} />
            </Grid>
            <Grid item xs={0} sm={4}></Grid>
          </Grid>
          <Box fontSize={14} display="flex" gap={{ xs: 4, sm: 6 }} zIndex={15}>
            <Link
              href="https://stre-0001-data.s3.eu-west-1.amazonaws.com/Stemreglement-BNTM-2024.pdf"
              color="inherit"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="footer.game_rules.label" />
            </Link>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
