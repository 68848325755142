import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  selectedContestantsAmount: number;
}

const StickyFooter = ({ selectedContestantsAmount }: Props) => {
  return (
    <Box
      position="sticky"
      bottom={0}
      zIndex={5}
      bgcolor="neutral01.800"
      display="flex"
      justifyContent="center"
      py={2}
      mb="1px"
    >
      <Typography variant="subtitle1" fontWeight={800}>
        <FormattedMessage
          id="vote.select_count.label"
          values={{ count: selectedContestantsAmount }}
        />
      </Typography>
    </Box>
  );
};

export default StickyFooter;
