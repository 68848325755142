import { Box, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { MainLayout } from 'components/@layout';

const Success = () => {
  return (
    <MainLayout>
      <Stack gap={3}>
        <Box width={{ xs: '260px', sm: 'auto' }} zIndex={15}>
          <Typography variant="h2">
            <FormattedMessage id="success.title" />
          </Typography>
        </Box>
        <Box width={{ xs: '246px', sm: '484px' }}>
          <Typography variant="h5">
            <FormattedMessage id="success.subtitle" />
          </Typography>
        </Box>
        <Box zIndex={15}>
          <Button variant="contained" href="https://www.streamz.be/">
            <FormattedMessage id="success.btn.label" />
          </Button>
        </Box>
      </Stack>
    </MainLayout>
  );
};

export default Success;
