import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { VOTE_OPEN, VoteSteps } from 'constants/vote.constants';
import { ContestantId } from 'types/contestant.types';

import { VoteLayout } from 'components/@layout';
import { SelectContestantsView, SubmitVoteView } from 'components/@vote';

const Vote = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<VoteSteps>(
    VoteSteps.selectContestants,
  );

  const [selectedContestantIds, setSelectedContestantIds] = useState<
    ContestantId[]
  >([]);

  useEffect(() => {
    if (!VOTE_OPEN) {
      navigate(Routes.Root);
    }
  }, [navigate]);

  const handleContestantSelect = (
    contestantId: ContestantId,
    isSelected: boolean,
  ) => {
    setSelectedContestantIds((prev) => {
      if (isSelected) {
        return prev.filter((id) => id !== contestantId);
      }
      return [...prev, contestantId];
    });
  };

  const handleStepChange = (step: VoteSteps) => {
    setCurrentStep(step);
  };

  return (
    <>
      <VoteLayout
        currentStep={currentStep}
        handleStepChange={handleStepChange}
        numberOfSelectedContestants={selectedContestantIds.length}
      >
        {currentStep === VoteSteps.selectContestants ? (
          <>
            <SelectContestantsView
              selectedContestantIds={selectedContestantIds}
              handleContestantSelect={handleContestantSelect}
              handleStepChange={handleStepChange}
            />
          </>
        ) : (
          <SubmitVoteView
            selectedContestantIds={selectedContestantIds}
            handleStepChange={handleStepChange}
          />
        )}
      </VoteLayout>
    </>
  );
};

export default Vote;
