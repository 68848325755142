import { Box, Container, Stack } from '@mui/material';
import { ReactNode } from 'react';

import bgWoman from 'assets/bg-woman.png';
import imgLogoTransparent from 'assets/logo/logo-transparent.png';

import { useMedia } from 'hooks';

import { Footer } from 'components/@common';
import { LogoBntm } from 'components/@icons';

interface Props {
  children: ReactNode;
}

const MainLayout = ({ children }: Props) => {
  const { isTablet, isMobile } = useMedia();

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      minWidth="100vw"
      position="relative"
    >
      <Box
        width="100%"
        height="100%"
        position="absolute"
        zIndex={10}
        sx={{ overflow: 'hidden' }}
      >
        <Box
          sx={{
            position: 'absolute',
          }}
          height="100%"
          width="100%"
        >
          <img
            src={bgWoman}
            style={{
              cursor: 'pointer',
              right: isMobile ? -250 : isTablet ? -300 : 0,
              maxHeight: '100%',
              position: 'absolute',
            }}
            alt="Dancer woman"
            onClick={() => window.open('https://www.streamz.be/')}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          background: 'url(/assets/bg-img.jpg)',
          backgroundSize: 'cover',
        }}
        flexGrow={1}
        display="flex"
      >
        <Stack minHeight="100%" mt={{ xs: 2, sm: 5 }} flexGrow={1}>
          <Stack gap={3} justifyContent="center" alignItems="center">
            <Box
              width={139}
              height={{ xs: 16, sm: 24 }}
              fontSize={0}
              lineHeight={0}
              textAlign="center"
            >
              <img
                src={imgLogoTransparent}
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  cursor: 'pointer',
                }}
                alt="Streamz logo"
                onClick={() => window.open('https://www.streamz.be/')}
              />
            </Box>
            <Box fontSize={0} lineHeight={0} textAlign="center" zIndex={15}>
              <LogoBntm height="64px" width="176px" />
            </Box>
          </Stack>
          <Container sx={{ height: '100%', mt: 5 }}>{children}</Container>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export default MainLayout;
