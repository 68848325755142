import { combineReducers } from 'redux';

import intlReducer from './intl/intl.slice';

const reducers = {
  intl: intlReducer,
};

const rootReducer = combineReducers(reducers);

type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
