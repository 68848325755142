import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import Success from './Success';

export default createRoute({
  element: <Success />,
  path: Routes.Success,
});
