import { Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral01[800],
      '&.Mui-checked': {
        color: theme.palette.global01[800],
      },
    }),
  },
};
