import { DevTool } from '@hookform/devtools';
import { BaseSyntheticEvent, ReactNode } from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

type SubmitHandler<TFieldValues extends FieldValues> = (
  data: TFieldValues,
  event: BaseSyntheticEvent,
  methods: UseFormReturn<TFieldValues>,
) => void;

interface Props<FormData extends FieldValues> {
  onSubmit: SubmitHandler<FormData>;
  defaultValues?: DefaultValues<FormData>;
  children: ReactNode | ((methods: UseFormReturn<FormData>) => ReactNode);
  enableDevTools?: boolean;
}

const Form = <FormData extends FieldValues>({
  defaultValues,
  onSubmit,
  children,
  enableDevTools,
}: Props<FormData>) => {
  const methods = useForm<FormData>({ defaultValues });
  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit((data, event) =>
          event ? onSubmit(data, event, methods) : undefined,
        )}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {children instanceof Function ? children(methods) : children}
      </form>
      {enableDevTools && <DevTool control={methods.control} />}
    </FormProvider>
  );
};

export default Form;
