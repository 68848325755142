import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { useMedia } from 'hooks';
import { Contestant, ContestantId } from 'types/contestant.types';

import SvgCheckbox from 'components/@icons/Checkbox';
import SvgCheckboxChecked from 'components/@icons/CheckboxChecked';

type Props = {
  contestant: Omit<Contestant, 'votes'>;
  selectedContestantIds: ContestantId[];
  index: number;
  handleContestantSelect: (
    contestantId: ContestantId,
    isSelected: boolean,
  ) => void;
};

const ContestantCard = ({
  selectedContestantIds,
  handleContestantSelect,
  contestant,
  index,
}: Props) => {
  const isSelected = selectedContestantIds.includes(contestant.contestantId);
  const { isMobile, isTablet, isDesktop } = useMedia();

  const [image, setImage] = useState(
    `/assets/contestants/${contestant.contestantId}.jpg`,
  );

  const mobileRight = () => {
    switch (index) {
      case 1:
        return -24;
      case 2:
        return -32;
      case 3:
        return -16;
      case 4:
        return -24;
      default:
        return 0;
    }
  };

  const addNumberXTimes = (x: number, times: number) => {
    if (times <= 0) {
      return 0; // If times is non-positive, the sum is 0.
    }

    let sum = 0;
    for (let i = 0; i < times; i++) {
      sum += x;
    }
    return sum;
  };

  return (
    <>
      {((!isTablet && !isDesktop) || isMobile) && <Box height={140} />}
      <Box
        display="flex"
        width={{ xs: '100%', lg: 320 }}
        height={{ xs: 140, lg: 442 }}
        top={{
          xs:
            140 * (index - 1) +
            46 -
            (index !== 1 ? addNumberXTimes(16, index) : 16),
          sm: 0,
          lg: 0,
        }}
        justifyContent={'space-between'}
        position={{ xs: 'absolute', sm: 'relative', lg: 'relative' }}
        flexDirection={{ lg: 'column' }}
      >
        {isDesktop && (
          <Box width={{ xs: 140, lg: 320 }} height={{ xs: 140, lg: 320 }} />
        )}

        <Box
          className="contestant-img"
          width={{ xs: 140, lg: 320 }}
          height={{ xs: 140, lg: 320 }}
          sx={(theme) => ({
            background: `url(${image})`,
            border: `4px solid ${theme.palette.global01[100]}`,
            position: 'absolute',
            right: `${
              isTablet && !isMobile ? 0 : isMobile ? mobileRight() : index * 8
            }px !important`,
            zIndex: index,
            backgroundRepeat: 'round',
            transition: theme.transitions.create(['background']),
          })}
          // onMouseOver={() =>
          //   isDesktop &&
          //   setImage(`/assets/contestants/${contestant.contestantId}-02.jpg`)
          // }
          onMouseOut={() =>
            isDesktop &&
            setImage(`/assets/contestants/${contestant.contestantId}.jpg`)
          }
        />

        <Stack
          gap={2}
          justifyContent="center"
          alignItems="center"
          direction={{ xs: 'row', lg: 'column' }}
          sx={(theme) => ({
            ':hover': {
              '.unchecked-checkbox': {
                stroke: theme.palette.global01[100],
                transition: theme.transitions.create(['stroke']),
              },
              '.checkbox-text': {
                color: theme.palette.global01[100],
                transition: theme.transitions.create(['color']),
              },
            },
            cursor: 'pointer',
            '-webkit-tap-highlight-color': 'transparent',
          })}
          onClick={() =>
            handleContestantSelect(contestant.contestantId, isSelected)
          }
        >
          {isSelected ? (
            <SvgCheckboxChecked width={40} height={40} color="white" />
          ) : (
            <SvgCheckbox
              className="unchecked-checkbox"
              width={40}
              height={40}
              stroke="black"
            />
          )}

          <Typography
            className="checkbox-text"
            variant="h4"
            color={isSelected ? 'textSecondary' : 'neutral01.800'}
          >
            {contestant.name}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default ContestantCard;
