import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ErrorCard } from 'components/@common';
import { FormCheckbox, FormEmailField } from 'components/@forms';

interface Props {
  isPending: boolean;
}
const FromPage = ({ isPending }: Props) => {
  const intl = useIntl();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Stack
        maxWidth={382}
        gap={3}
        mb={{ sm: 5 }}
        sx={(theme) => ({
          '& .MuiInputBase-root': {
            color: theme.palette.neutral01[800],
          },
        })}
      >
        {(errors?.privacyPolicy?.type === 'required' ||
          errors?.email?.type === 'required') && (
          <ErrorCard
            label={<FormattedMessage id="form.validation.general" />}
          />
        )}
        <FormEmailField name="email" fullWidth={false} required />
        <FormCheckbox
          name="privacyPolicy"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({ id: 'general.error.required' }),
            },
          }}
          label={
            <FormattedMessage
              id="form.privacy_policy.label"
              values={{
                asterisk: (value) => <a style={{ color: 'red' }}>{value}</a>,
                a: (value) => (
                  <a
                    style={{ textDecoration: 'underline', color: 'black' }}
                    href="https://stre-0001-data.s3.eu-west-1.amazonaws.com/Stemreglement-BNTM-2024.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {value}
                  </a>
                ),
              }}
            />
          }
        />
        <FormCheckbox
          name="promotionalConsent"
          label={<FormattedMessage id="form.promotional_consent.label" />}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        color="secondary"
        type="submit"
        loading={isPending}
        sx={{
          width: { xs: '100%', sm: 'fit-content' },
          px: 3,
          py: 2,
          boxShadow: 'none',
          textTransform: 'none',
          alignSelf: 'center',
        }}
      >
        <FormattedMessage id="vote.submit.btn.label" />
      </LoadingButton>
    </Box>
  );
};

export default FromPage;
