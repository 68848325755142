import { Components, Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.neutral01[800],
        borderRadius: 0,
      },
      '&.MuiOutlinedInput-root': {
        ':not(.Mui-disabled):not(.Mui-error) fieldset': {
          borderColor: theme.palette.neutral01[800],
        },
      },
      '&.MuiOutlinedInput-root:hover': {
        ':not(.Mui-disabled):not(.Mui-error) fieldset': {
          borderColor: theme.palette.neutral01[800],
        },
      },
    }),
  },
};
