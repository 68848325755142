import { AxiosInstance } from 'axios';

import userApi from './user.api';
import voteApi from './vote/vote.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public getUser = userApi.getUser;
  public createUser = userApi.createUser;
  public updateUser = userApi.updateUser;

  // VOTE
  public submitVote = voteApi.submitVote;
}

export default RestApiService;
