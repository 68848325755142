import { Box, Checkbox as MuiCheckbox, Typography } from '@mui/material';
import { ReactNode } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import SvgCheckboxCheckedSmall from 'components/@icons/CheckboxCheckedSmall';

interface Props {
  label: ReactNode;
}

const FormCheckbox = ({
  name,
  label,
  rules,
}: Props & Pick<UseControllerProps, 'rules' | 'name'>) => {
  const form = useFormContext();

  return (
    <Controller
      rules={rules}
      render={({ field }) => {
        return (
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <MuiCheckbox
                {...field}
                checked={field.value}
                icon={
                  <Box
                    width={20}
                    height={20}
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.neutral01[800]}`,
                    })}
                  />
                }
                checkedIcon={<SvgCheckboxCheckedSmall />}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              <Typography variant="body2" color="neutral01.800">
                {label}
              </Typography>
            </Box>
          </Box>
        );
      }}
      control={form.control}
      name={name}
    />
  );
};

export default FormCheckbox;
