/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { RouteObject } from 'react-router-dom';

import confirm from './confirm';
import home from './home';
import notFound from './not-found';
import success from './success';
import vote from './vote';

export const routes: RouteObject[] = [home, notFound, confirm, success, vote];
