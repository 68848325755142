import { Components, Theme } from '@mui/material';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    label: ({ theme }) => ({
      color: theme.palette.neutral01[800],
      fontSize: '0.875rem',
      lineHeight: '23.8px',
    }),
    asterisk: {
      display: 'none',
    },
  },
};
