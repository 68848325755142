import { TextFieldProps } from '@mui/material';
import { UseControllerProps } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { VALIDATIONS } from 'constants/validation.constants';

import { FormTextField } from 'components/@forms';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<TextFieldProps, 'name'>;

const FormEmailField = ({ rules, ...rest }: Props) => {
  const intl = useIntl();

  return (
    <FormTextField
      label={<FormattedMessage id="form.label.email" />}
      rules={{
        required: errorMessageFor(intl, {
          type: 'required',
          label: 'email',
          message: intl.formatMessage({
            id: 'form.validation.email.format',
          }),
        }),
        pattern: {
          value: VALIDATIONS.email,
          message: intl.formatMessage({
            id: 'form.validation.email.format',
          }),
        },
        minLength: {
          value: VALIDATIONS.emailMinLength,
          message: intl.formatMessage({
            id: 'form.validation.email.format',
          }),
        },
        ...rules,
      }}
      type="email"
      inputMode="email"
      autoComplete="email"
      {...rest}
    />
  );
};

export default FormEmailField;
