import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTheme = createTheme({
  palette: {
    primary: { main: '#fff' },
    secondary: { main: '#560245' },
    error: {
      dark: '#771818',
      main: '#D40C48',
      light: '#FEF3F2',
    },
    warning: {
      dark: '#AF6409',
      main: '#DE7F0B',
      light: '#F7B05A',
    },
    info: {
      main: '#223A57',
      light: '#E5ECF5',
    },
    success: {
      main: '#276402',
      light: '#E8EFE5',
    },
    text: {
      primary: '#FFFFFF',
      // primary: '#000', // TODO: react hook form color
      secondary: '#560245',
    },
    neutral01: {
      100: '#FFFFFF',
      800: '#000',
    },
    global01: {
      100: '#560245',
    },
  },
});
