import { isProd } from 'utils/env.utils';

const NOW = new Date().getTime();

const VOTE_START_DATE = isProd()
  ? Date.UTC(2024, 7, 29, 22, 30) // -> uses monthIndex!!
  : Date.UTC(2024, 7, 26, 9, 0);

const VOTE_END_DATE = isProd()
  ? Date.UTC(2024, 7, 31, 21, 59) // -> uses monthIndex!!
  : Date.UTC(2024, 7, 31, 21, 59);

export const VOTE_NOT_STARTED = NOW < VOTE_START_DATE;
export const VOTE_OPEN = NOW > VOTE_START_DATE && NOW < VOTE_END_DATE;
export const VOTE_ENDED = NOW > VOTE_END_DATE;

export enum VoteSteps {
  selectContestants,
  submitVote,
}
