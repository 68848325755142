import { TextField, TextFieldProps } from '@mui/material';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<TextFieldProps, 'name'>;

const FormTextField = ({ rules, name, helperText, ...rest }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        return (
          <TextField
            fullWidth
            {...field}
            inputRef={ref}
            error={!!error}
            helperText={
              (error?.type !== 'required' && error?.message) ?? helperText
            }
            {...rest}
          />
        );
      }}
    />
  );
};

export default FormTextField;
