import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  label: ReactNode;
}

const ErrorCard = ({ label }: Props) => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #D40C48',
        ['@media (min-width:382px)']: {
          width: 382,
        },
        ['@media (max-width:382px)']: {
          width: '100%',
        },
      }}
      px={2}
      py={1.5}
      mb={3}
      alignSelf="center"
    >
      <Typography color="#D40C48" variant="body2">
        {label}
      </Typography>
    </Box>
  );
};

export default ErrorCard;
