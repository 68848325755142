import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&:hover': {
        color: theme.palette.primary.main,
        boxShadow: 'none',
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #FFF',
        '& .MuiButton-startIcon': {
          backgroundColor: theme.palette.global01[100],
          color: 'white',
        },
      },
      backgroundColor: 'transparant',
      borderRadius: 0,
      color: theme.palette.neutral01[800],
      fontWeight: 800,
      padding: 5,
      boxShadow: 'none',
      textTransform: 'none',
      '& .MuiButton-startIcon': {
        backgroundColor: theme.palette.neutral01[800],
        color: 'white',
        transition: theme.transitions.create(['background-color']),
      },
    }),
    textSizeMedium: ({ theme }) => ({
      ':hover': {
        color: theme.palette.global01[100],
        background: 'none',
      },
    }),
    containedPrimary: ({ theme }) => ({
      color: theme.palette.global01[100],
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      marginBottom: 24,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '800',
      lineHeight: '24px',
      ':hover': {
        color: theme.palette.global01[100],
      },
    }),
    containedSecondary: ({ theme }) => ({
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.neutral01[100],
        opacity: 0.2,
      },
      '&:hover': {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), ${theme.palette.global01[100]}`,
        color: theme.palette.neutral01[100],
      },
    }),
  },
};
