import { createTheme } from '@mui/material/styles';

import { baseTheme } from './base';

export const typography = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: '"aktiv-grotesk", sans-serif',
    h1: {
      fontWeight: 900,
      fontSize: '7.5rem',
      lineHeight: '100px',
      letterSpacing: '-8.4px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '4.5rem',
        lineHeight: '56px',
        letterSpacing: '-5.04px',
      },
    },
    h2: {
      fontWeight: '800',
      fontSize: '4.5rem',
      lineHeight: '72px',
      letterSpacing: '-5.04px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
        lineHeight: '40px',
        letterSpacing: '-2.8px',
      },
    },
    h3: {
      fontWeight: '800',
      fontSize: '4rem',
      lineHeight: '81.92px',
      letterSpacing: '-2.56px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '24px',
        letterSpacing: '-0.96px',
      },
    },
    h4: {
      fontWeight: '800',
      fontSize: '2.158rem',
      lineHeight: '44.19px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '24px',
      },
    },
    h5: {
      fontWeight: '500',
      fontSize: '1.5rem',
      lineHeight: '30.72px',
      letterSpacing: '-0.32px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '20.48px',
        letterSpacing: '-0.28px',
      },
    },
    h6: {
      fontWeight: '500',
      fontSize: '1.5rem',
      lineHeight: '24px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '24px',
      },
    },
    subtitle1: {
      fontWeight: 800,
      fontSize: '1rem',
      lineHeight: '20.48px',
      letterSpacing: '-0.32px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        lineHeight: '16px',
        letterSpacing: '-0.28px',
      },
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '24px',
      },
    },
    body2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '23.8px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        lineHeight: '23.8px',
      },
    },
    overline: {
      fontWeight: 500,
      fontSize: '0.8rem',
      lineHeight: '20px',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        lineHeight: '20px',
      },
    },
  },
}).typography;
