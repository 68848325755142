export enum Routes {
  NotFound = '*',
  Root = '/',
  Vote = '/vote',
  Confirm = '/confirm',
  Success = '/success',

  // generic routes
  RedirectNotAuthorized = '/login',
}
