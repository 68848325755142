import { Box, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { VOTE_NOT_STARTED, VOTE_OPEN } from 'constants/vote.constants';

import { MainLayout } from 'components/@layout';

const Home = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Stack gap={3} height="100%">
        <Box display="flex" flexDirection="column" zIndex={15}>
          <Typography variant="h2">
            <FormattedMessage id="home.pre_title" />
          </Typography>
          <Typography variant="h1" whiteSpace="pre-line">
            <FormattedMessage id="home.title" />
          </Typography>
        </Box>
        {VOTE_NOT_STARTED && (
          <Box width={{ xs: '246px', sm: '482px' }} zIndex={15}>
            <Typography variant="h5">
              <FormattedMessage id="home.subtitle" />
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" flexGrow={1}>
          {VOTE_OPEN ? (
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={{ xs: 1, sm: 0 }}
              gap={3}
            >
              <Box
                width={{ xs: '246px', sm: '482px' }}
                display="flex"
                flexGrow={1}
                flexDirection="column"
                zIndex={15}
              >
                <Typography variant="h5">
                  <FormattedMessage id="home.subtitle" />
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(Routes.Vote)}
                  sx={{
                    width: {
                      xs: '100%',
                      sm: 'auto',
                    },
                    zIndex: 15,
                  }}
                >
                  <FormattedMessage id="home.vote_now.btn.label" />
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="h6" fontWeight={800} whiteSpace="pre-line">
              {VOTE_NOT_STARTED ? (
                <FormattedMessage
                  id="home.vote_from.label"
                  values={{ date: '30 augustus 00u30' }}
                />
              ) : (
                <FormattedMessage id="home.after_vote.paragraph" />
              )}
            </Typography>
          )}
        </Box>
      </Stack>
    </MainLayout>
  );
};

export default Home;
